import Compareable from '@multichannel/sdk/src/Comparable';
import { b64EncodeUnicode, b64DecodeUnicode } from '@multichannel/sdk/src/api/Util/Base64';

export interface ScopedStorage {
  get(key: string): any;
  getAndRemove(key: string): any;
  remove(key: string): void;
  set(key: string, value: any): any;
}

export class Storage extends Compareable {

  private _api: any = undefined;

  constructor() {
    super();
  }

  public setApiInstance = (api) => {
    this._api = api;
  }

  private _getKey(name: string): string {
    return b64EncodeUnicode(this._api?.config.uuid + ':' + name);
  }

  private _readContainer(name: string): any {
    if (typeof this._api === typeof undefined) {
      throw new Error('Inject api within Storage.setApiInstance');
    }

    const originalKey = this._getKey(name);

    const versionedKey = this._api?.configKey + '.' + name;

    const encodedItem = localStorage.getItem(originalKey);

    if (encodedItem && encodedItem !== 'undefined') {
      let exposedItem = b64DecodeUnicode(encodedItem);
      //handling the 'switch sdk version case' #id 10097
      if (name === 'auth') {
        const authItemObject = JSON.parse(exposedItem);
        authItemObject.token = b64EncodeUnicode(JSON.stringify(authItemObject.credentials));
        delete authItemObject.credentials;
        exposedItem = JSON.stringify(authItemObject);
      }
      localStorage.setItem(versionedKey, exposedItem);
      localStorage.setItem(originalKey, undefined);
    }

    let result = {};
    try {
      const unsignedItem = localStorage.getItem(name);
      if (unsignedItem && unsignedItem !== 'undefined') {
        localStorage.setItem(versionedKey, unsignedItem);
        localStorage.setItem(name, undefined);
      }
      result = JSON.parse(localStorage.getItem(versionedKey));
    } catch (e) {
      this._api?.debug('api.storage').trace(e);
    }
    return result || {};
  }

  _writeContainer(name: string, content: any): void {
    if (typeof this._api === typeof undefined) {
      throw new Error('Inject api within Storage.setApiInstance');
    }
    localStorage.setItem(this._api?.configKey + '.' + name, JSON.stringify(content));
  }

  public clear(): void {
    //rescue certain developer helper and user settings before wipe
    const importMapOverrides = Object.entries(localStorage).filter(([key, _value] : [string, string]) => key.startsWith('devtools') || key.startsWith('import-map-override') || key.includes('userSettings'));
    localStorage.clear();
    //restore them accordingly
    importMapOverrides.map(([key, value] : [string, string]) => localStorage.setItem(key, value));
  }

  public getContainer(context: string): any {
    return this._readContainer(context);
  }

  public get(key: string, context: string = ''): any {
    const container = this._readContainer(context);
    return container[key];
  }

  public getAndRemove(key: string, context: string = ''): any {
    const value = this.get(key, context);
    this.remove(key, context);
    return value;
  }

  public registerKeyCombination(): void {
    const clearLocalStorage = e => {
      const evtobj = window.event ? event : e;
      if (evtobj.keyCode === 67 /* C */ && evtobj.ctrlKey && evtobj.shiftKey && evtobj.altKey) {
        this.clear();
      }
    };

    document.addEventListener('keydown', clearLocalStorage, false);
  }

  public remove(key: string, context: string = ''): void {
    this.set(key, undefined, context);
  }

  /**
   * Returns a scoped interface to this class.
   *
   * Use this method in class getters to simplify access to storage scopes:
   *
   * const scopedStorage = vier.storage.scoped('my-custom-storage-space');
   * scopedStorage.get('some-key');
   *
   * This avoids the need to specify the scope on every action against the storage space.
   *
   * @param scopeName
   * @returns {{set: (function(*=, *=): *), get: (function(*=): *)}}
   */
  public scoped(scopeName: string): ScopedStorage {
    return {
      set         : (key, value) => this.set(key, value, scopeName),
      get         : key => this.get(key, scopeName),
      remove      : key => this.remove(key, scopeName),
      getAndRemove: key => this.getAndRemove(key, scopeName),
    };
  }

  public set(key: string, value: any, context: string = ''): any {
    const container = this._readContainer(context);
    if (typeof value === 'undefined') {
      delete container[key];
    } else {
      container[key] = value;
    }
    this._writeContainer(context, container);
  }

  public setContainer(context: string, container: string): any {
    return this._writeContainer(context, container);
  }
}

export default new Storage();
